@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Black.ttf') format('truetype');
  font-weight: 900;
}

body {
  font-family: 'Pretendard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'Pretendard', monospace;
  
}
